import React, { useState } from 'react';
import "./PriceRange.css";
import { Box, Slider } from '@mui/material';

const PriceRange = ({ min, max, value, onValueChange }) => {
    const [localSliderValue, setLocalSliderValue] = useState(value || [min, max]);

    // Handle real-time slider updates locally
    const handleLocalSliderChange = (event, newValue) => {
        setLocalSliderValue(newValue);
    };

    // Commit changes to the parent once the user stops interacting with the slider
    const handleSliderChangeCommitted = (event, newValue) => {
        if (onValueChange) {
            onValueChange(newValue);
        }
    };

    // Input field handlers for min and max values
    const handleMinInputChange = (e) => {
        const newMin = Math.min(parseInt(e.target.value, 10) || min, localSliderValue[1]);
        setLocalSliderValue([newMin, localSliderValue[1]]);
    };

    const handleMaxInputChange = (e) => {
        const newMax = Math.max(parseInt(e.target.value, 10) || max, localSliderValue[0]);
        setLocalSliderValue([localSliderValue[0], newMax]);
    };

    return (
        <div className="products-sidebar-accordion-container">
            <Box sx={{ width: "80%", textAlign: "center" }}>
                <Slider
                    value={localSliderValue}
                    onChange={handleLocalSliderChange} // Real-time updates
                    onChangeCommitted={handleSliderChangeCommitted} // Final commit
                    min={min}
                    max={max}
                    valueLabelDisplay="on"
                    style={{ color: "#101728" }}
                    className="products-sidebar-range"
                />
                <div className="products-sidebar-ranges">
                    <input
                        type="number"
                        className="products-sidebar-range-input"
                        placeholder={`Min ${min}`}
                        value={localSliderValue[0]}
                        onChange={handleMinInputChange}
                    />
                    <div> - </div>
                    <input
                        type="number"
                        className="products-sidebar-range-input"
                        placeholder={`Max ${max}`}
                        value={localSliderValue[1]}
                        onChange={handleMaxInputChange}
                    />
                </div>
            </Box>
        </div>
    );
};

export default PriceRange;
