import "./RelatedProducts.css"
import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Card, Title } from "../../../../components";
import { Container, Col } from "react-bootstrap";

const RelatedProducts = ({ url, resultData }) => {
  const itemsList = resultData?.details?.related?.items || [];
  return (
    // <div className="">
      <Container>
        <Title title={"Related Products"} para={"Lorem ipsum dolor sit amet, consectetur adipisicing elit."} />
        <div className="cards">

          <Splide className="product-slider"
            options={{
              gap: "20px",
              perPage: 5,
              pagination: false,
              breakpoints: {
                1200: {
                  perPage: 4,
                },
                992: {
                  perPage: 3,
                },
                768: {
                  perPage: 2,
                },
                576: {
                  perPage: 1,
                }
              }
            }}
          >


            {itemsList.map((item, index) => (
                <SplideSlide key={index}>
                
                  <Card
                    title={item.item_group}
                    para={item.web_item_name}
                    image={url + item.website_image}
                    pricebefore={item.before_discount_price_list_rate}
                    priceafter={item.discounted_price_list_rate}
                    sale={item.sale}
                    name={item.name}
                  />
                
              </SplideSlide>
            ))}
          </Splide>

        </div>

      </Container>
    // {/* </div> */}

  );
};

export default RelatedProducts;


