import React from 'react';
import "./Home.css";
import { Hero, Categories, Product, TrendyProduct, SpecialOffer, ProductBanner, TabBay, SplitInFour, ClientReviews } from '../../Features/Home/index';

export const Home = ({ data, contentData, innerPageData, itemsList, categoryList, url, apiMethod }) => {

    const heroSection = data?.find(item => item.section === "Hero Section");
    const trendyCategorySection = data?.find(item => item.section === "Trendy Category Section");
    const homeProductsCardsSection = data?.find(item => item.section === "Home products Cards Section");
    const productSection = data?.find(item => item.section === "Product Section");
    const categorySection = data?.find(item => item.section === "Category Section");
    const paySection = data?.find(item => item.section === "Pay Section");
    const paySplitSection = data?.find(item => item.section === "Pay Split Section");
    const clientReviewSection = data?.find(item => item.section === "Client Review Section");
    const specialOffersSection = data?.find(item => item.section === "Special Offers Section");

    console.log(heroSection)
    return (
        <div className='home'>
            <a href="https://wa.me/YOUR_PHONE_NUMBER" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" className="whatsapp-icon" />
            </a>
            {heroSection && (
                <Hero contentData={contentData} url={url} section={heroSection} />
            )}
            {productSection && (
                <Product contentData={contentData} itemsList={itemsList} categoryList={categoryList} url={url} apiMethod={apiMethod} />
            )}
            {categorySection && (
                <Categories contentData={contentData} categoryList={categoryList} url={url} />
            )}

            {trendyCategorySection && (
                <TrendyProduct contentData={contentData} url={url} />
            )}

            {specialOffersSection && (
                <SpecialOffer contentData={contentData} itemList={itemsList} url={url} />
            )}
            {homeProductsCardsSection && (
                <ProductBanner contentData={contentData} url={url} />
            )}
            {paySection && (
                <TabBay contentData={contentData} url={url} />
            )}
            {paySplitSection && (
                <SplitInFour contentData={contentData} url={url} />
            )}

            {clientReviewSection && (
                <ClientReviews contentData={contentData} url={url} />
            )}
        </div>
    );
}

export default Home;
