import React from 'react'
import "./Card.css"
import heart from "../../asset/images/svg/heart.svg"
import stars from "../../asset/images/svg/stars.svg"

const Card = ({ title, para, image, pricebefore, priceafter, sale, name,currency }) => {
    if (!pricebefore || !priceafter) {
        sale = 0;
    }
    else {
        sale = (pricebefore !== 0 && priceafter !== 0) ? Math.round(((pricebefore - priceafter) / pricebefore) * 100) : 0;

        if (isNaN(sale)) {
            sale = 0;
        }
    }

   return (
    
        <div className="card-product" onClick={() => window.location.href = '/product/' + name}>
            <div className="card-header">
                {sale != 0 ? (
                    <>
                        <h2>{sale}%</h2>
                    </>
                ) : <h1></h1>}
                <div>
                    <img src={heart} />
                </div>
            </div>
            <div className="card-img">
                <img src={image} />
            </div>
            <div className="card-content">
                <h1>{title}</h1>
                <p>{para}</p>
                <img src={stars} />
                <div className="price">
                    <h3>{pricebefore}<span>{currency}</span></h3><h3>-</h3><h3>{priceafter}<span>{currency}</span></h3>
                </div>
            </div>
        </div>
    )
}

export default Card
