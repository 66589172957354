import React, { useState, useEffect, useRef } from 'react';
import "./ProductsPage.css"
import { CategoriesSlider, Filters } from '../../Features/ProductsPage'
import { Card, Paginations, Loading } from '../../components/index';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const ProductsPage = ({ url, limit, apiMethod }) => {
    const location = useLocation();
    const categoryParam = new URLSearchParams(location.search).get('categories') ?? null;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [productsData, setProductsData] = useState([]);
    const [categoriesGlobal, setCategoriesGlobal] = useState([]);
    const [brandsGlobal, setBrandGlobal] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000);
    const [selectedCategories, setCategories] = useState([new URLSearchParams(location.search).get('categories')]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [categoryParams, setCategoryParams] = useState([]); // this get when pressed on categoryCard in category page
    const prevSelectedBrandsRef = useRef([]);
    const prevSearchTermRef = useRef("");
    const prevSelectedCategoriesRef = useRef([]);
    const prevMinPriceRef = useRef(0);
    const prevMaxPriceRef = useRef(10000);
    const [loading, setLoading] = React.useState(true);


    // Responsive Sidebar Filter 
    const [activeSidebar, setActiveSidebar] = useState(false);
    const handleSidebar = () => {
        setActiveSidebar(!activeSidebar);
    };
    // End Responsive Sidebar Filter 


    useEffect(() => {
        fetchDataByPage(currentPage);
    }, []);


    useEffect(() => {
        if (categoryParam) {
            setCategoryParams(categoryParam);
        }
    }, [categoryParam]);


    useEffect(() => {
        fetchDataByPage(currentPage);
    }, []);


    useEffect(() => {

        if (selectedBrands && selectedBrands !== prevSelectedBrandsRef.current) {
            fetchDataByPage(currentPage);
            prevSelectedBrandsRef.current = selectedBrands;
        }
    }, [selectedBrands]);

    useEffect(() => {

        if (searchTerm && searchTerm !== prevSearchTermRef.current) {
            fetchDataByPage(currentPage);
            prevSearchTermRef.current = searchTerm;
        }
    }, [searchTerm]);

    useEffect(() => {

        if (selectedCategories && selectedCategories !== prevSelectedCategoriesRef.current) {
            fetchDataByPage(currentPage);
            prevSelectedCategoriesRef.current = selectedCategories;
        }
    }, [selectedCategories]);

    useEffect(() => {

        if ((minPrice || maxPrice) && (minPrice !== prevMinPriceRef.current || maxPrice !== prevMaxPriceRef.current)) {
            fetchDataByPage(currentPage);
            prevMinPriceRef.current = minPrice;
            prevMaxPriceRef.current = maxPrice;
        }
    }, [minPrice, maxPrice]);



    const fetchDataByPage = async (page) => {

        const startIndex = (page - 1) * limit;
        const searchParam = searchTerm ? `&search=${searchTerm}` : '';
        const minPriceParam = minPrice ? `&min_price=${minPrice}` : '';
        const maxPriceParam = maxPrice ? `&max_price=${maxPrice}` : '';
        var categoriesParam = "";
        if (selectedCategories.length > 0) {
            categoriesParam = "&item_groups=";
            categoriesParam = categoriesParam + selectedCategories.join(',');
        }
        var brandsParam = "";
        if (selectedBrands.length > 0) {
            brandsParam = "&brands=";
            brandsParam = brandsParam + selectedBrands.join(',');
        }


        const apiURL = `${url}${apiMethod}.content_by_page_name?name=products&start=${startIndex}&limit=${limit}${searchParam}${minPriceParam}${maxPriceParam}${categoriesParam}${brandsParam}`;
        try {

            const response = await axios.get(apiURL);
            setLoading(false);
            setCategoriesGlobal(response?.data?.result?.categories);
            setBrandGlobal(response?.data?.result?.brands);
            const data = response?.data?.result?.items?.items;
            const pagination = response?.data?.result?.items?.pagination;

            setProductsData(data);
            setTotalPages(pagination?.metadata?.total_pages);
            setTotalProducts(pagination?.metadata?.total_data);

        } catch (error) {
            console.log('Error fetching products:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchDataByPage(page);
    };
    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
    };
    const handlePrice = (minPrice, maxPrice) => {
        setMinPrice(minPrice);
        setMaxPrice(maxPrice);
    };



    const handleChangeBrand = (e) => {
        const brandName = e.target.value;
        const isChecked = e.target.checked;
        const myNextList = [...selectedBrands];
        if (isChecked) {
            if (!selectedBrands.includes(brandName)) {
                myNextList.push(brandName);
            }
        } else {
            const index = myNextList.indexOf(brandName);
            if (index !== -1) {
                myNextList.splice(index, 1);
            }
        }
        setSelectedBrands(myNextList);
    };


    const handleChangeCategory = (value, checked) => {
        const categoryName = value;
        const isChecked = checked;
        const myNextList = [...selectedCategories];
        if (isChecked) {
            if (!selectedCategories.includes(categoryName)) {
                myNextList.push(categoryName);
            }
        } else {
            const index = myNextList.indexOf(categoryName);
            if (index !== -1) {
                myNextList.splice(index, 1);
            }
        }
        setCategories(myNextList);
    };

    const handleChangeCategory2 = (values, checked) => {
        const categoryNames = values;
        const isChecked = checked;
        const myNextList = [...selectedCategories];
        if (isChecked) {
            categoryNames.forEach((name) => {
                if (!selectedCategories.includes(name)) {
                    myNextList.push(name);
                }
            })

        } else {

            categoryNames.forEach((name) => {
                const index = myNextList.indexOf(name);
                if (index !== -1) {
                    myNextList.splice(index, 1);
                }
            })
        }
        setCategories(myNextList);
    };



    return (
        <div>

            {loading == true ?
                (
                    <Loading></Loading>
                )
                :
                <div className='products'>
                    <div className={`sidebar-responsive-container ${activeSidebar ? 'active' : ''}`}>
                        <div className='sidebar-responsive-overlay' onClick={handleSidebar}></div>
                        <div className='sidebar-responsive'>
                            <Filters url={url}
                                onSearch={handleSearch} onPriceFilter={handlePrice}
                                CategoriesFilter={categoriesGlobal}
                                onCategoryChange={handleChangeCategory}
                                onCategoryChange1={handleChangeCategory2}
                                BrandsFilter={brandsGlobal}
                                onChangeBrand={handleChangeBrand}
                                categoryParams={selectedCategories}
                            />                        
                        </div>
                    </div>
                    <Container>
                        <div className='show-filters-container'>
                            <div className='show-filters' onClick={handleSidebar}>
                                    <h1 className='show-filters-title' >Show Filters</h1>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        width="256"
                                        height="256"
                                        viewBox="0 0 256 256"
                                        xmlSpace="preserve"
                                    >
                                        <g
                                            style={{
                                                stroke: 'none',
                                                strokeWidth: 0,
                                                strokeDasharray: 'none',
                                                strokeLinecap: 'butt',
                                                strokeLinejoin: 'miter',
                                                strokeMiterlimit: 10,
                                                fill: 'none',
                                                fillRule: 'nonzero',
                                                opacity: 1,
                                            }}
                                            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                        >
                                            <path
                                                d="M 15.898 90 c -1.657 0 -3 -1.343 -3 -3 V 54.88 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 V 87 C 18.898 88.657 17.555 90 15.898 90 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 74.103 60.069 c -1.657 0 -3 -1.343 -3 -3 V 3 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 v 54.069 C 77.103 58.727 75.76 60.069 74.103 60.069 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 45 90 c -1.657 0 -3 -1.343 -3 -3 V 28.319 c 0 -1.657 1.343 -3 3 -3 c 1.657 0 3 1.343 3 3 V 87 C 48 88.657 46.657 90 45 90 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 45 31.319 c -5.914 0 -10.726 -4.812 -10.726 -10.726 S 39.086 9.868 45 9.868 s 10.726 4.812 10.726 10.726 S 50.914 31.319 45 31.319 z M 45 15.868 c -2.605 0 -4.726 2.12 -4.726 4.726 s 2.12 4.726 4.726 4.726 s 4.726 -2.12 4.726 -4.726 S 47.605 15.868 45 15.868 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 15.898 57.88 c -5.915 0 -10.726 -4.812 -10.726 -10.726 c 0 -5.915 4.812 -10.726 10.726 -10.726 c 5.914 0 10.726 4.812 10.726 10.726 C 26.624 53.068 21.812 57.88 15.898 57.88 z M 15.898 42.428 c -2.606 0 -4.726 2.12 -4.726 4.726 c 0 2.605 2.12 4.726 4.726 4.726 c 2.605 0 4.726 -2.12 4.726 -4.726 C 20.624 44.548 18.503 42.428 15.898 42.428 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 74.103 75.521 c -5.915 0 -10.727 -4.812 -10.727 -10.727 c 0 -5.914 4.812 -10.726 10.727 -10.726 c 5.914 0 10.726 4.812 10.726 10.726 C 84.828 70.71 80.017 75.521 74.103 75.521 z M 74.103 60.069 c -2.606 0 -4.727 2.12 -4.727 4.726 c 0 2.606 2.12 4.727 4.727 4.727 c 2.605 0 4.726 -2.12 4.726 -4.727 C 78.828 62.189 76.708 60.069 74.103 60.069 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 15.898 42.428 c -1.657 0 -3 -1.343 -3 -3 V 3 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 v 36.428 C 18.898 41.085 17.555 42.428 15.898 42.428 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 45 15.868 c -1.657 0 -3 -1.343 -3 -3 V 3 c 0 -1.657 1.343 -3 3 -3 c 1.657 0 3 1.343 3 3 v 9.868 C 48 14.524 46.657 15.868 45 15.868 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                            <path
                                                d="M 74.103 90 c -1.657 0 -3 -1.343 -3 -3 V 72.521 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 V 87 C 77.103 88.657 75.76 90 74.103 90 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'round',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(0,0,0)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                            />
                                        </g>
                                    </svg>
                            </div>
                        </div>
                        <Row>
                            
                            <div className='col-md-4 col-lg-3 col-12 filters-big-screen'>
                                <Filters url={url}
                                    onSearch={handleSearch} onPriceFilter={handlePrice}
                                    CategoriesFilter={categoriesGlobal}
                                    onCategoryChange={handleChangeCategory}
                                    onCategoryChange1={handleChangeCategory2}
                                    BrandsFilter={brandsGlobal}
                                    onChangeBrand={handleChangeBrand}
                                    categoryParams={selectedCategories}
                                />
                            </div>
                            <div className='col-lg-9 col-12'>
                                {categoryParam ? (
                                    <div className="banner">
                                        <img
                                            src={require("../../asset/images/Products/banner3.jpg")}
                                            alt="Banner"
                                            className="banner-image"
                                        />
                                    </div>
                                ) : categoriesGlobal.length > 0 ? (
                                    <CategoriesSlider categories={categoriesGlobal} url={url} />
                                ) : (<div className='d-none'></div>)
                                }
                                <div className="products-list">
                                    {productsData && productsData.length === 0 ? (
                                        <center>  <p>No Products</p> </center>

                                    ) : (
                                        <div className="card-grid">
                                            {productsData && productsData.map((item, index) => (
                                                <Card
                                                    key={item.web_item_name}
                                                    title={item.item_group}
                                                    para={item.web_item_name}
                                                    image={url + item.website_image}
                                                    pricebefore={item.before_discount_price_list_rate}
                                                    priceafter={item.discounted_price_list_rate}
                                                    sale={item.sale}
                                                    name={item.name}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex justify-content-center" style={{ marginTop: '60px' }}>
                                    <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                </div>

                                {/* {totalProducts !== 0 && (
                                    <div className='total'>
                                        Total: {totalProducts}
                                    </div>
                                )} */}
                            </div>
                        </Row>

                    </Container>
                </div>
            }</div>
    )
}


export default ProductsPage;