import React from 'react'
import "./ProductsCategory.css"
import { Col, Row } from 'react-bootstrap'

const ProductsCategory = ({ title, image, color, href, handleClick }) => {
  const handleLinkClick = (event) => {
    if (handleClick) {
      event.preventDefault(); // Prevent the default link behavior
      handleClick(); // Call the click handler
    }
  };

  return (
    <a href={href} onClick={handleLinkClick} className='product-category-container'>
      <div className="products-category" style={{ backgroundColor: color }}>
        <div className="products-category-title">{title}</div>
        <img src={image} alt="Product" className="image-product-category" />
      </div>
    </a>
  );
};

export default ProductsCategory