import React from 'react'
import "./Privacy.css"
import { Container } from 'react-bootstrap'

const Privacy = () => {
    return (
        <Container className='privacy-container'>
            <h1 className='privacy-title'>Privacy Policy</h1>
            <p className='privacy-title-desc'>Published on <span>Jan 02, 2024</span></p>
           
            <div className='privacy-para mt-4'>More Than Idea (MI) is fully committed to protecting your personal data</div>
            <div className='privacy-para'>By accepting the privacy policy of MI customers, you allow us to collect, use and disclose your personal information only in accordance with the privacy policy and in a very limited way. Please note that your personal data will never be sold, used or displayed to any party with or without compensation.</div>
        
            <div className='privacy-para bold mt-3'>How to get MI to obtain personal information from customers?</div>
            <div className='privacy-para'> 
            When opening a new account on the MI Store website, we request a set of personal data for the customer, as this data must be completed in a correct manner to complete the registration process on the site and to ensure that there is no false or misleading information.
            </div>

            <div className='privacy-para bold mt-3'>Personal information we collect</div>
            <div className='privacy-para'> Your name, address, email, phone number, and other basic information.</div>
            <div className='privacy-para'> Financial information such as credit / debit card, or bank account details in some transactions.</div>
            <div className='privacy-para'> To ensure the validity of customer data, we may have to ask for proof of your identity and place of residence, such as a copy of an identity card, passport, driver’s license or certificate of registration / warranty.</div>

            <div className='privacy-para bold mt-3'>Security measures</div>
            <div className='privacy-para'>Upon completion of the registration process, you can start purchasing from the MI site, is strictly protected against fraud and abuse through the following procedures:</div>
            <ul className='privacy-list'>
                <li className='privacy-para'>Record and store your IP address and number of times you log onto the site.</li>
                <li className='privacy-para'>Collecting usage and access data that allows us to prevent the opening of multiple accounts or fraudulent behaviour or to protect our systems against unlawful attacks.</li>
                <li className='privacy-para'>We may share this data with professional and reliable fraud management services that help us analyse this data. We use cookies, which are small text files that are stored on a customer's computer for record-keeping purposes. The information collected and stored by cookies may be against personal data that we keep on our systems.</li>
                <li className='privacy-para'>
                    We obtain this personal and confidential information through the following sources:
                    <ul className='privacy-list'>
                        <li className='privacy-para'>Information you provide to us on the site, through forms, written forms and other forms of data entry.</li>
                        <li className='privacy-para'>Information entered on the site, by storing your personal information without completing or submitting a form.</li>
                        <li className='privacy-para'>Information from third parties entered indirectly to verify the authenticity of the user and to prevent any fraud.</li>
                        <li className='privacy-para'>Information from third parties that you certify and agree to provide us with.</li>
                        <li className='privacy-para'>Information about sales and purchase and account history.</li>
                    </ul>
                </li>
            </ul>

            <div className='privacy-para bold mt-3'>Internal use of personal information</div>
            <div className='privacy-para'>
                We collect, store and process your personal information on our servers in the <span>United States of America</span>, and we maintain physical, electronic and procedural guarantees to protect our services 24 hours a day.
            </div>
            <div className='privacy-para'>
                We use your personal information to activate your account on MI site, implement your operations, and provide all services on the site.
            </div>
            <div className='privacy-para'>
                We also share some information such as banks or credit / debit card processing services and identity verification services as part of our regular business operations, but we disclose only the last six numbers of your credit or debit card in order to make sure the data is correct Customer (all financial data is stored using completely secure encryption).
            </div>
            <div className='privacy-para'>
                The purpose of sharing this data is to identify and verify clients, reduce fraud, other criminal activities, and financial risks on our site. When suspicious of a user’s identity or being subjected to a fraud, the service provider has the right to re-inquire about any personal data. By using our services, you agree to share your personal data for these purposes.
                Control pf personal information
            </div>
            <div className='privacy-para'>
                If there is a change in personal information (such as name, address or phone number), you must update this information through the "Profile" section of your account on the MI website, or by contacting the MI customer service. MI is not responsible for any losses arising from your failure to keep your personal data confidential. If you wish to suspend your account, you will have to contact customer service. We are committed to maintaining some of your records for a minimum of 5 years after account closure.
            </div>

            <div className='privacy-para bold mt-3'>How to protect your personal data</div>
            <div className='privacy-para'>
                The MI Store is committed to handling your account information with a high level of security and protecting personal data against unauthorized access, use, or disclosure. Credit / debit card and bank account details are only stored in encrypted form on offline computers. Once you log into your account and transfer your information over the Internet, all internet connections are secured using SSL: Secure Socket Layer technology with 128-bit high security to ensure that your private data does not reach anyone on the internet.
            </div>
            <div className='privacy-para'>
                However, this level of protection can only be 100% effective if you follow your own security policies. You must not share your account data with anyone. If you are concerned that any of your personal data has been revealed, you must change it immediately after logging in to your account
            </div>
        </Container>
    )
}

export default Privacy
