import React from 'react'
import "./CategoriesSlider.css"
import { ProductsCategory } from '../../../../components'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Col } from 'react-bootstrap';
import '@splidejs/splide/css';
const CategoriesSlider = ({categories,url}) => {

  return (
    <div className="categories-slider">
      <Splide
        options={{
          gap: "15px",
          perPage: 3,
          fixedWidth: "215px",
          pagination: false,
          arrows: false,
          breakpoints: {
            1400: {
              perPage: 3,
            },
            1200: {
              perPage: 2,
            },
            992: {
              perPage: 3,
            },
            768: {
              perPage: 2,
            },
            576: {
              perPage: 2,
            }
          }
        }}
      >       
           {categories.map((category,index) => (
            <Col xxl={3} xl={4} lg={6} md={4} sm={6} xs={8}>
              <SplideSlide>
                <ProductsCategory
                  title={category.name}
                  image={
                    category.image
                      ? url + category.image
                      : require("../../../../asset/images/Categories/category3.png")
                  }
                  color= {index%2==0?"#bca186": "#101728"} 
                />
              </SplideSlide>
            </Col>
         
          ))} 
      </Splide>
    </div>
  )
}

export default CategoriesSlider
