import React from 'react'
import "./Categories.css"
import { Title, Category } from '../../../../components'
import { Container, Row } from 'react-bootstrap'

const Categories = ({ contentData, categoryList, url }) => {
  const categories = Array.isArray(categoryList) && categoryList.length > 0  ? categoryList.filter(category => category.is_featured === 1):[];

  const handleClick = (category) => {
    if (category.is_group === 0) {
      const categoryName = category.name;
      // navigate(`/products?categories=${categoryName}`);
      window.location.href = `/products?categories=${categoryName}`;
    }
    else{
      window.location.href = '/category/' + category.name;
    }
  };

  return (
    <Container className={`${categories ? 'd-none' : ''}`}>

      <Title title={contentData.featured_part_2_title ?? "Featured Categories"} para={contentData.featured_part_2_description ?? "Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Voluptate,"} />

      <Row className='mt-5'>


        {categories.map((category, index) => (
          <Category
            title={category.name}
            para={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate,"}
            image={
              category.image
                ? url + category.image
                : require("../../../../asset/images/Categories/category3.png")
            }
            color={index % 2 === 0 ? require("../../../../asset/images/Categories/maincolorcategory.png") :
              require("../../../../asset/images/Categories/blackcategory.png")
            }
            handleClick={() => handleClick(category)}
            href={category.is_group === 0 ? '/products' : null}
          />
        ))}
      </Row>

    </Container>
  )
}

export default Categories
