import React from 'react'
import "./Status.css"
import { Col } from "react-bootstrap"

const Status = ({ image, showTime, num, day, finalStatus }) => {
    return (
        <Col md={3} className="col-md-3" data-aos="fade-right" data-aos-duration={`${showTime}`}>
            <div className={`box ${finalStatus ? 'final-status' : ''}`}>
                <img src={image} />
                <div className="box-content">
                    <h1>{num}</h1>
                    <h2>{day}</h2>
                </div>
            </div>
        </Col>
    )
}

export default Status